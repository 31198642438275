import React from 'react';
import { css } from '@emotion/react';
import { navigate } from 'gatsby';
import RoutePaths from '@/utils/routes';
import { useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';
import AppButtonV2 from '@/components/AppButtonV2';

const NotFoundPage: React.FC = () => {
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);
  return (
    <div
      css={css`
        min-height: 100vh;
        padding-top: 30px;
        padding-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e7edf3;
        position: relative;
        width: 100%;
        padding: 30px 20px 30px;
        .section-login {
          max-width: 480px;
          width: 100%;
          font-weight: 300;
          .wrapper-login {
            padding: 50px;
            border-radius: 20px;
            background-color: #2e0249;
            background-image: none;
            backdrop-filter: blur(14px);
            color: #fff;
            .notfound-header {
              font-family: 'Fira Sans', sans-serif;
              margin-top: 20px;
              border-radius: 5px;
              color: #fff;
              text-align: center;
              margin-bottom: 16px;
              font-size: 34px;
              line-height: 36px;
              font-weight: 700;
            }
            .message {
              margin-bottom: 22px;
            }
          }
        }
      `}
    >
      <div className="section-login">
        <div className="wrapper-login">
          <h2 className="notfound-header">404 Not Found</h2>
          <p className="message">Sorry, This page does not exist or you do not have permission to access it!</p>
          <AppButtonV2
            size="large"
            width="100%"
            onClick={() => {
              if (!myProfile) {
                navigate(RoutePaths.LOGIN);
              } else if (myProfile?.user_type === UserType.AGENT) {
                navigate(RoutePaths.AGENT_REPORT);
              } else if (myProfile?.user_type === UserType.MANAGER) {
                navigate(RoutePaths.MANAGER);
              } else if (myProfile?.user_type === UserType.BROKER) {
                navigate(RoutePaths.BROKER_MY_AGENTS);
              }
            }}
          >
            Okay
          </AppButtonV2>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
